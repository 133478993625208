import React from 'react';
import { Modal } from 'react-bootstrap';

import CloseIcon from '~reactIcons/Close.icon.react';

import { IViewTicketAffectedItem, IViewTicketResponse } from '~utils/global.types.react';

import { strings } from '~utils/strings';
import { MediumBasicText } from '~utils/texts.react';

import './ViewTicketModal.react.scss';
import { safeClickHandler } from '~utils/global.helpers.react';

type ViewTicketModalProps = {
  isViewTicketModalShown: boolean;
  onModalClosed: () => void;
  provider: string;
  viewTicketResponse: IViewTicketResponse | null;
};

const ViewTicketModal = ({
  isViewTicketModalShown,
  provider,
  onModalClosed,
  viewTicketResponse,
}: ViewTicketModalProps) => {
  const hasAffectedActionItems = viewTicketResponse?.AffectedActionItems?.length || false;
  const ticketLink = viewTicketResponse?.TicketLink;
  const totalSkipped = viewTicketResponse?.TotalSkipped || 0;

  const hideViewTicketModal = () => {
    onModalClosed();
  };

  const openTicketLink = safeClickHandler(() => {
    if (!ticketLink) {
      return;
    }
    window.open(ticketLink, '_blank');
  });

  const buildTitlePrefix = (item: IViewTicketAffectedItem) => {
    if (item?.ResourceNamespace && item?.ResourceName) {
      return `${item.ResourceNamespace} - ${item.ResourceName}:`;
    }
    if (item?.ResourceNamespace) {
      return `${item.ResourceNamespace}:`;
    }
    if (item?.ResourceName) {
      return `${item.ResourceName}:`;
    }
    return '';
  };

  return (
    <Modal onHide={hideViewTicketModal} show={isViewTicketModalShown}>
      <Modal.Body className="custom-modal-body">
        <div className="custom-modal-title view-ticket-modal__custom-modal-title">
          <h3>{strings.viewTicketModal.createTicket}</h3>
          <div className="custom-close-icon">
            <CloseIcon onClick={hideViewTicketModal} />
          </div>
        </div>
        {hasAffectedActionItems &&
          (ticketLink ? (
            <Modal.Footer className="create-ticket-modal__modal-footer">
              <a className="btn btn-primary" href={ticketLink} onClick={openTicketLink}>
                {strings.viewTicketModal.viewTicket}
              </a>
            </Modal.Footer>
          ) : (
            <div>
              <>
                <p
                  className={MediumBasicText({
                    weight: strings.textStyling.regular,
                    topMargin: strings.textStyling.lg,
                  })}
                >
                  {strings.viewTicketModal.subTitle.replace('$provider', provider)}
                </p>
                <div className="view-ticket-modal__action-items">
                  <ul>
                    {(viewTicketResponse?.AffectedActionItems || []).map((item: IViewTicketAffectedItem) => (
                      <li>
                        <a href={item.TicketLink} target="_blank" rel="noopener noreferrer">
                          {buildTitlePrefix(item)} {item.Title || ' '}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            </div>
          ))}
      </Modal.Body>
      {!hasAffectedActionItems && ticketLink && (
        <Modal.Footer className="create-ticket-modal__modal-footer">
          <a className="btn btn-primary" href={ticketLink} onClick={openTicketLink}>
            {strings.viewTicketModal.viewTicket}
          </a>
        </Modal.Footer>
      )}
      {!hasAffectedActionItems && !ticketLink && (
        <Modal.Footer className="create-ticket-modal__modal-footer">
          <p className="text-left">{strings.viewTicketModal.noTicketCreated}</p>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ViewTicketModal;
