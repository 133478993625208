import React, { useState, useEffect, useMemo, SyntheticEvent, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select, { SingleValue } from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import toast, { Toaster } from 'react-hot-toast';

import flag from '@/assets/icons/flag.svg';
import flagBlack from '@/assets/icons/flag-black.svg';
import plus from '@/assets/icons/plus.svg';
import trash from '@/assets/icons/trash.svg';
import ViewTicketModal from '~reactComponents/ViewTicketModal/ViewTicketModal.react';
import CloseIcon from '~reactIcons/Close.icon.react';

import {
  GithubReposType,
  IListTicketType,
  ISavedProviderConfigField,
  ISavedProviderConfigs,
  UpdatedTicketType,
  UpdatedVulnTicketType,
  VulnerabilityTicketMode,
} from './CreateTicketModal.types.react';
import { ActionItem, JiraProject } from '~views/organization/actionItems/ActionItems.types.react';
import { IViewTicketResponse, IRoute, IRouter, OptionType, JiraUser } from '~globalTypes';

import { INTEGRATIONS } from '~reactComponents/NavigationReact/Navigation.config.react';
import { PAGES } from '~reactHelpers';
import { getCurrentUser, sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~logger';
import { Title, CardTitleDefault } from '~utils/texts.react';

import './CreateTicketModal.react.scss';

const INTERVAL_DURATION = 10000;
const MAX_POLLING_TIME = 1000 * 60 * 20;

type CreateTicketProps = {
  imageSHAs?: string[];
  isFrom?: string;
  vulnerabilityTicketMode?: VulnerabilityTicketMode;
  items?: ActionItem[];
  listID?: number;
  logEventKey?: string;
  repositoryActionItemIDs?: number[];
  route: IRoute;
  showModal: boolean;
  router: () => IRouter;
  closeModal: () => void;
};

type ProjectOptionType = OptionType & {
  provider: 'GitHub' | 'Jira' | 'Azure';
};

const CreateTicketModal = ({
  imageSHAs,
  isFrom,
  vulnerabilityTicketMode,
  items,
  listID,
  logEventKey,
  repositoryActionItemIDs,
  route,
  showModal,
  router,
  closeModal,
}: CreateTicketProps) => {
  let interval: any = null;
  const org = route?.params?.org;
  const [githubProjects, setGithubProjects] = useState<string[]>([]);
  const [jiraProjects, setJiraProjects] = useState<JiraProject[]>([]);
  const [azureProjects, setAzureProjects] = useState<string[]>([]);
  const [selectedProject, setSelectedProject] = useState<ProjectOptionType>();
  const [selectedReporter, setSelectedReporter] = useState<OptionType | null>(null);
  const [selectedAssignee, setSelectedAssignee] = useState<OptionType | null>(null);
  const [allProjects, setAllProjects] = useState<ProjectOptionType[]>([]);
  const [assignableUsers, setAssignableUsers] = useState<OptionType[]>([]);
  const [savedProviderConfigs, setSavedProviderConfigs] = useState<ISavedProviderConfigs>();
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
  const [issueType, setIssueType] = useState<string>('');
  const [isViewTicketModalShown, setIsViewTicketModalShown] = useState<boolean>(false);
  const [viewTicketResponse, setViewTicketResponse] = useState<IViewTicketResponse | null>(null);
  const [error, setError] = useState<string>('');

  const currentGithubProjects = useRef<string[]>([]);
  const currentAzureProjects = useRef<string[]>([]);
  const currentJiraProjects = useRef<JiraProject[]>([]);

  useEffect(() => {
    refreshProjects();
    // unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  const hasNewProjects = (currentProjects: string[], newProjects: string[]) => {
    if (currentProjects.length !== newProjects.length) {
      return true;
    }

    currentProjects.sort();
    newProjects.sort();

    return JSON.stringify(currentProjects) !== JSON.stringify(newProjects);
  };

  const hasNewJiraProjects = (currentProjects: JiraProject[], newProjects: JiraProject[]) => {
    if (currentProjects.length !== newProjects.length) {
      return true;
    }

    currentProjects.sort((a, b) => (a.label > b.label ? 1 : -1));
    newProjects.sort((a, b) => (a.label > b.label ? 1 : -1));

    const nJiraProjects = newProjects.length;

    for (let i = 0; i < nJiraProjects; i++) {
      if (currentProjects[i].label !== newProjects[i].label) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    getProjectOptions();
  }, [githubProjects, jiraProjects, azureProjects]);

  useEffect(() => {
    if (showModal) {
      pollForProjects();
    }
  }, [showModal]);

  const isIssueTypeShown = useMemo(
    () => selectedProject && !(selectedProject.provider === strings.github),
    [selectedProject],
  );

  const isReporterAndAssigneeShown = useMemo(
    () => selectedProject && selectedProject.provider === strings.jira,
    [selectedProject],
  );

  const getSavedProviderConfigs = (selectedProvider: string | null) =>
    selectedProvider ? localStorage.getItem(selectedProvider) : null;

  const initProviderConfigs = (selectedProvider: string | null) => {
    if (!selectedProvider) {
      return;
    }
    const defaultProviderConfigs = {
      fields: [],
    } as ISavedProviderConfigs;
    if (selectedProvider !== strings.github) {
      defaultProviderConfigs.savedIssueType =
        selectedProvider === strings.azure ? strings.general.issue : strings.general.bug;
    }
    localStorage.setItem(selectedProvider, JSON.stringify(defaultProviderConfigs));
    setSavedProviderConfigs(defaultProviderConfigs);
    setIssueType(defaultProviderConfigs.savedIssueType || strings.general.bug);
  };

  const initIssueType = (parsedSavedProviderConfigs: ISavedProviderConfigs) => {
    return parsedSavedProviderConfigs?.savedIssueType || strings.general.bug;
  };

  const updateSavedProviderConfigs = (
    savedProviderConfigs: ISavedProviderConfigs | undefined,
    selectedProvider: string | null,
  ) => {
    if (!selectedProvider) {
      return;
    }
    setSavedProviderConfigs(savedProviderConfigs ? { ...savedProviderConfigs } : ({} as ISavedProviderConfigs));
    localStorage.setItem(selectedProvider, JSON.stringify(savedProviderConfigs));
  };

  const removeUnsavedProviderConfigs = (
    savedProviderConfigs: ISavedProviderConfigs | undefined,
    selectedProvider: string | null,
  ) => {
    if (!savedProviderConfigs) {
      return;
    }
    savedProviderConfigs.fields = savedProviderConfigs.fields.filter((field) => field.isSaved);
    updateSavedProviderConfigs(savedProviderConfigs, selectedProvider);
  };

  useEffect(() => {
    if (selectedProject) {
      const selectedProvider = selectedProject.provider || strings.github;
      setSelectedProvider(selectedProvider);
      const savedProviderConfigs = getSavedProviderConfigs(selectedProvider);
      if (savedProviderConfigs) {
        const parsedSavedProviderConfigs = JSON.parse(savedProviderConfigs);
        removeUnsavedProviderConfigs(parsedSavedProviderConfigs, selectedProvider);
        setIssueType(initIssueType(parsedSavedProviderConfigs));
      } else {
        initProviderConfigs(selectedProvider);
      }
    }
  }, [selectedProject]);

  const refreshGithubProjects = (repositories: GithubReposType[]) => {
    const filteredRepos: GithubReposType[] | [] = repositories.filter((repo) => {
      if (!repo.Tickets) return false;
      return repo.InstallationID;
    });

    const filteredRepoNames = filteredRepos.map((repo) => repo.Name);

    if (hasNewProjects(currentGithubProjects.current, filteredRepoNames)) {
      currentGithubProjects.current = filteredRepoNames;
      setGithubProjects(filteredRepoNames);
    }
  };

  const refreshJiraProjects = (projects: Record<string, string>) => {
    const jiraProjects: JiraProject[] = Object.entries(projects).map(([key, label]) => {
      return { key, label };
    });

    if (hasNewJiraProjects(currentJiraProjects.current, jiraProjects)) {
      currentJiraProjects.current = jiraProjects;
      setJiraProjects(jiraProjects);
    }
  };

  const refreshAzureProjects = (projects: string[]) => {
    if (hasNewProjects(currentAzureProjects.current, projects)) {
      currentAzureProjects.current = projects;
      setAzureProjects(projects);
    }
  };

  const refreshProjects = async (): Promise<void> => {
    try {
      const requests = [
        sendRequest('GET', `/v0/organizations/${org}/ci/repositories`, {}, null),
        sendRequest('GET', `/v0/organizations/${org}/jira/projects`, {}, null),
        sendRequest('GET', `/v0/organizations/${org}/azure/projects`, {}, null),
      ];
      const [retrievedGithubProjects, retrievedJiraProjects, retrievedAzureProjects] =
        await Promise.allSettled(requests);

      if (retrievedGithubProjects.status === 'fulfilled') {
        refreshGithubProjects(retrievedGithubProjects.value);
      }
      if (retrievedJiraProjects.status === 'fulfilled' && jiraProjects) {
        refreshJiraProjects(retrievedJiraProjects.value);
      }
      if (retrievedAzureProjects.status === 'fulfilled') {
        refreshAzureProjects(retrievedAzureProjects.value);
      }
    } catch (e) {
      logger.logError('error_retrieving_projects', e);
      toast.error(<b>{`${strings.sorry}, ${strings.noProjects}`}</b>);
    }
  };

  const getProjectOptions = () => {
    const allProjects: ProjectOptionType[] = [];
    githubProjects.forEach((projectName) =>
      allProjects.push({ label: projectName + ' - ' + strings.github, value: projectName, provider: 'GitHub' }),
    );
    jiraProjects.forEach((project) =>
      allProjects.push({ label: project.label + ' - ' + strings.jira, value: project.key, provider: 'Jira' }),
    );
    azureProjects.forEach((projectName) =>
      allProjects.push({ label: projectName + ' - ' + strings.azure, value: projectName, provider: 'Azure' }),
    );
    setAllProjects(allProjects);
  };

  const createTicket = () => {
    if (listID) {
      createListTicket();
    } else {
      createActionItemsTicket();
    }
  };

  const getAssignableUsers = async (projectKey: string): Promise<{ users: JiraUser[] }> => {
    try {
      const response = (await sendRequest(
        'GET',
        `/v0/organizations/${org}/jira/projects/${projectKey}/assignable-users`,
        {},
        null,
      )) as { users: JiraUser[] };
      return response;
    } catch (e) {
      logger.logError('error_retrieving_assignable_users', e);
      if (e.status !== 404) {
        toast.error(<b>{`${strings.sorry}, ${strings.general.pleaseTryAgain}`}</b>);
      }
    }
    return { users: [] };
  };

  const createListTicket = async () => {
    if (!selectedProject) {
      return;
    }

    try {
      const data = {
        Provider: selectedProject.provider,
        ProjectName: selectedProject.value,
        Reporter: selectedReporter?.value || undefined,
        Assignee: selectedAssignee?.value || undefined,
        Fields: buildFields(),
        Labels: [],
      } as IListTicketType;
      if ([strings.jira, strings.azure].includes(selectedProvider || '')) {
        data.IssueType = issueType;
      }
      const response = await sendRequest(
        'PATCH',
        `/v0/organizations/${org}/lists/${listID}/ticket`,
        { data, showSuccessAlert: true, showErrorAlert: true },
        null,
      );
      if (response && response.Success) {
        if (interval) {
          clearInterval(interval);
          interval = null;
        }
        setViewTicketResponse(response);
        setIsViewTicketModalShown(true);
      }
    } catch (e: any) {
      setError(e.message || '');
      logger.logError('error_creating_list_ticket', e);
      toast.error(<b>{`${strings.sorry}, ${strings.listTicketCreationError} ${strings.general.pleaseTryAgain}`}</b>);
    }
  };

  const createActionItemsTicket = async () => {
    if (!selectedProject) {
      return;
    }

    try {
      const updatedTicket =
        isFrom && [PAGES.VulnerabilitiesAllImages].includes(isFrom)
          ? buildVulnUpdatedTicket(
              selectedProject.value,
              selectedProject.provider,
              selectedReporter?.value,
              selectedAssignee?.value,
            )
          : buildUpdatedTicket(
              selectedProject.value,
              selectedProject.provider,
              selectedReporter?.value,
              selectedAssignee?.value,
            );
      const response = await sendRequest('PATCH', getCreateActionItemsTicketURL(), { data: updatedTicket }, null);
      if (response && response.Success) {
        if (interval) {
          clearInterval(interval);
          interval = null;
        }
        setIsViewTicketModalShown(true);
        setViewTicketResponse(response);
        sendLogEvent(updatedTicket);
      }
    } catch (e: any) {
      setError(e.message || '');
      logger.logError('error_creating_action_item_ticket', e);
      toast.error(<b>{`${strings.ticketCreationError} ${strings.general.pleaseTryAgain}`}</b>);
    }
  };

  const buildKeyValueForFields = (fields: any) => {
    if (!savedProviderConfigs?.fields?.length) {
      return;
    }

    for (const field of savedProviderConfigs.fields) {
      try {
        fields[field.name] = JSON.parse(field.details);
      } catch (error) {
        if (error) {
          fields[field.name] = field.details;
        }
      }
    }
  };

  const buildFields = () => {
    if (!savedProviderConfigs) {
      return {};
    }
    const fields = {};
    if (savedProviderConfigs.fields.length) {
      buildKeyValueForFields(fields);
    }
    return fields;
  };

  const buildVulnUpdatedTicket = (
    projectName: string | undefined,
    provider: string | undefined,
    reporter: string | undefined,
    assignee: string | undefined,
  ) => {
    const updatedTicket = {
      provider: provider,
      projectName: projectName,
      reporter: reporter || undefined,
      assignee: assignee || undefined,
      fields: buildFields(),
    } as UpdatedVulnTicketType;
    if ([strings.jira, strings.azure].includes(selectedProvider || '')) {
      updatedTicket.issueType = issueType;
    }
    if (isFrom === PAGES.VulnerabilitiesAllImages) {
      updatedTicket.imageSHAs = imageSHAs;
    }
    return updatedTicket;
  };

  const buildUpdatedTicket = (
    projectName: string | undefined,
    provider: string | undefined,
    reporter: string | undefined,
    assignee: string | undefined,
  ) => {
    const updatedTicket = {
      Provider: provider,
      ProjectName: projectName,
      Reporter: reporter || undefined,
      Assignee: assignee || undefined,
      Fields: buildFields(),
    } as UpdatedTicketType;
    if ([strings.jira, strings.azure].includes(selectedProvider || '')) {
      updatedTicket.IssueType = issueType;
    }
    if (isFrom === PAGES.Repository) {
      updatedTicket.ActionItemIDs = repositoryActionItemIDs;
    } else {
      updatedTicket.ActionItems = items;
    }

    return updatedTicket;
  };

  const getCreateActionItemsTicketURL = () => {
    if (isFrom === PAGES.Repository) {
      return `/v0/organizations/${org}/ci/action-items/tickets`;
    }
    if (isFrom === PAGES.VulnerabilitiesAllImages) {
      let url = `/v0/organizations/${org}/images/vulnerabilities/tickets`;
      if (vulnerabilityTicketMode) {
        url += `?mode=${vulnerabilityTicketMode}`;
      }
      return url;
    }
    return `/v0/organizations/${org}/action-items/tickets`;
  };

  const sendLogEvent = (updatedTicket: UpdatedTicketType | UpdatedVulnTicketType) => {
    if (!updatedTicket || !logEventKey) return;
    logger.logEvent(logEventKey, updatedTicket);
  };

  const pollForProjects = () => {
    if (interval != null) {
      clearInterval(interval);
    }
    let count = 0;
    interval = setInterval(() => {
      if (count++ * INTERVAL_DURATION > MAX_POLLING_TIME) {
        clearInterval(interval);
      } else {
        refreshProjects();
      }
    }, INTERVAL_DURATION);
  };

  const goToIntegrationsPage = () => {
    router().push({
      name: INTEGRATIONS,
    });
  };

  const handleCloseModal = () => {
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
    removeUnsavedProviderConfigs(savedProviderConfigs, selectedProvider);
    closeModal();
  };

  const handleProjectSelection = async (selection: SingleValue<ProjectOptionType>) => {
    if (!selection) {
      return;
    }
    setSelectedReporter(null);
    setSelectedAssignee(null);
    setAssignableUsers([]);
    const selectedProjectKey = selection.value;
    if (typeof selectedProjectKey === 'string') {
      setSelectedProject(selection);
      const assignables = await getAssignableUsers(selectedProjectKey);
      const userOptions = assignables.users.map((user: JiraUser) => ({
        label: user.displayName,
        value: user.accountId,
      }));
      setAssignableUsers(userOptions);
      const currentUser = await getCurrentUser();
      if (currentUser?.user?.Email) {
        const reporter = assignables.users.find((jiraUser) => jiraUser?.emailAddress === currentUser.user.Email);
        if (reporter) {
          setSelectedReporter({ label: reporter.displayName, value: reporter.accountId });
        }
      }
    }
  };

  const upsertOrRemoveIssueType = () => {
    if (!savedProviderConfigs) {
      return;
    }
    if (savedProviderConfigs.savedIssueType) {
      delete savedProviderConfigs.savedIssueType;
    } else {
      savedProviderConfigs.savedIssueType = issueType;
    }
  };

  const toggleIssueTypeActiveStatus = () => {
    if (selectedProvider && savedProviderConfigs) {
      upsertOrRemoveIssueType();
      updateSavedProviderConfigs(savedProviderConfigs, selectedProvider);
    }
  };

  const onIssueTypeChanged = (e: SyntheticEvent) => {
    const updatedIssueType = (e.target as HTMLInputElement).value;
    setIssueType(updatedIssueType);
    if (savedProviderConfigs?.savedIssueType) {
      savedProviderConfigs.savedIssueType = updatedIssueType;
      updateSavedProviderConfigs(savedProviderConfigs, selectedProvider);
    }
  };

  const addAdditionalFields = () => {
    if (savedProviderConfigs) {
      savedProviderConfigs?.fields.push({ id: uuidv4(), name: '', details: '', isSaved: false });
      updateSavedProviderConfigs(savedProviderConfigs, selectedProvider);
    }
  };

  const updateSavedProviderConfigsByField = (selectedField: ISavedProviderConfigField) => {
    if (!selectedField || !savedProviderConfigs) {
      return;
    }
    savedProviderConfigs.fields = savedProviderConfigs.fields.map((field) =>
      field.id === selectedField.id ? selectedField : field,
    );
    updateSavedProviderConfigs(savedProviderConfigs, selectedProvider);
  };

  const toggleConfigFieldSaveStatus = (selectedField: ISavedProviderConfigField) => {
    if (!selectedField || !savedProviderConfigs) {
      return;
    }
    selectedField.isSaved = !selectedField.isSaved;
    updateSavedProviderConfigsByField(selectedField);
  };

  const removeAdditionalField = (selectedField: ISavedProviderConfigField) => {
    if (!selectedField || !savedProviderConfigs) {
      return;
    }
    savedProviderConfigs.fields = savedProviderConfigs.fields.filter((field) => field.id !== selectedField.id);
    updateSavedProviderConfigs(savedProviderConfigs, selectedProvider);
  };

  const onFieldChanged = (
    e: SyntheticEvent,
    selectedField: ISavedProviderConfigField,
    fieldName: 'name' | 'details',
  ) => {
    if (!selectedField || !savedProviderConfigs) {
      return;
    }
    selectedField[fieldName] = (e.target as HTMLInputElement).value;
    updateSavedProviderConfigsByField(selectedField);
  };

  if (isViewTicketModalShown && selectedProvider) {
    return (
      <ViewTicketModal
        isViewTicketModalShown={isViewTicketModalShown}
        provider={selectedProvider || ''}
        viewTicketResponse={viewTicketResponse}
        onModalClosed={() => {
          setIsViewTicketModalShown(false);
          handleCloseModal();
        }}
      />
    );
  }

  return (
    <Modal show={showModal} onHide={handleCloseModal} className="create-ticket-modal" size="lg">
      <Modal.Header className="create-ticket-header">
        <div className="create-ticket-header__content">
          <Modal.Title className="create-ticket-title-bar">
            <h1
              className={Title({
                size: strings.textStyling.md,
                weight: strings.textStyling.medium,
                bottomMargin: strings.textStyling.smBottom,
              })}
            >
              {strings.general.createTicket}
            </h1>
            <div className="custom-close-icon">
              <CloseIcon onClick={handleCloseModal} width="1rem" height="1rem" />
            </div>
          </Modal.Title>
          <div className="bottom-bar">
            <Button onClick={() => goToIntegrationsPage()} variant="white" className="text-nowrap integrations-button">
              {strings.manageIntegration}
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="create-ticket-modal__error">{error}</div>}
        <div className="create-ticket-modal-body">
          <div className="project-div">
            <h2
              className={CardTitleDefault({ topMargin: strings.textStyling.md })}
            >{`${strings.general.Project}${strings.punctuation.colon}`}</h2>
            <Select
              isMulti={false}
              isSearchable
              options={allProjects}
              className="create-ticket-select"
              onChange={handleProjectSelection}
            />
          </div>
          {isReporterAndAssigneeShown && (
            <>
              <div className="project-div">
                <h2
                  className={CardTitleDefault({ topMargin: strings.textStyling.md })}
                >{`${strings.general.Reporter}${strings.punctuation.colon}`}</h2>
                <Select
                  isMulti={false}
                  isSearchable
                  value={selectedReporter}
                  options={assignableUsers}
                  className="create-ticket-select"
                  onChange={setSelectedReporter}
                />
              </div>
              <div className="project-div">
                <h2
                  className={CardTitleDefault({ topMargin: strings.textStyling.md })}
                >{`${strings.general.Assignee}${strings.punctuation.colon}`}</h2>
                <Select
                  isMulti={false}
                  isSearchable
                  value={selectedAssignee}
                  options={assignableUsers}
                  className="create-ticket-select"
                  onChange={setSelectedAssignee}
                />
              </div>
            </>
          )}
          {isIssueTypeShown && (
            <>
              <div className="labels-div">
                <h2 className={CardTitleDefault({ topMargin: strings.textStyling.md })}>{`${
                  selectedProvider === strings.azure
                    ? strings.createTicketModal.workItemType
                    : strings.createTicketModal.issueType
                }${strings.punctuation.colon}`}</h2>
                <div className="create-ticket-modal__input-container">
                  <input
                    className="create-ticket-modal__input"
                    defaultValue={savedProviderConfigs?.savedIssueType || ''}
                    onChange={onIssueTypeChanged}
                    value={issueType}
                  />
                  <div className="create-ticket-modal__image-container" onClick={toggleIssueTypeActiveStatus}>
                    {savedProviderConfigs?.savedIssueType !== null &&
                    savedProviderConfigs?.savedIssueType !== undefined ? (
                      <img src={flagBlack} alt={strings.createTicketModal.configSaved} />
                    ) : (
                      <img src={flag} alt={strings.createTicketModal.configHasNotBeenSaved} />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {selectedProject && (
          <>
            <div className="create-ticket-modal__divider"></div>
            <h2 className={CardTitleDefault()}>{`${strings.createTicketModal.additionalFields}`}</h2>
            <div className="create-ticket-modal__additional-fields-button" onClick={addAdditionalFields}>
              <img src={plus} alt={strings.createTicketModal.addMoreFields} />
              <span>{strings.createTicketModal.addTicketField}</span>
            </div>
            <div className="create-ticket-modal__additional-fields-container">
              {savedProviderConfigs?.fields.map((field) => (
                <div key={field.id} className="create-ticket-modal__additional-field">
                  <input
                    className="create-ticket-modal__input create-ticket-modal__estimate-input"
                    onChange={(e) => onFieldChanged(e, field, 'name')}
                    placeholder={strings.createTicketModal.field}
                    defaultValue={field.name}
                  />
                  <div className="create-ticket-modal__input-container">
                    <input
                      className="create-ticket-modal__input create-ticket-modal__two-hours-input"
                      onChange={(e) => onFieldChanged(e, field, 'details')}
                      placeholder={strings.createTicketModal.value}
                      defaultValue={field.details}
                    />
                    <div
                      className="create-ticket-modal__image-container"
                      onClick={() => toggleConfigFieldSaveStatus(field)}
                    >
                      {field?.isSaved ? (
                        <img src={flagBlack} alt={strings.createTicketModal.configSaved} />
                      ) : (
                        <img src={flag} alt={strings.createTicketModal.configHasNotBeenSaved} />
                      )}
                    </div>
                  </div>
                  <img
                    className="create-ticket-modal__trash"
                    src={trash}
                    alt={strings.createTicketModal.removeAdditionalField}
                    onClick={() => removeAdditionalField(field)}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="submit-btn" onClick={() => createTicket()} disabled={!selectedProject}>
          {strings.general.createTicket}
        </Button>
      </Modal.Footer>
      <Toaster />
    </Modal>
  );
};

export default CreateTicketModal;
